import Hero from "./Component/Hero"
import Navbar from "./Component/Navbar";
import Footer from "./Component/footer";
import Confirmation from "./Component/Confirmation";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <div>
      <Navbar />
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<Hero />} />
            <Route path="/confirmation" element={<Confirmation />} />
          </Routes>
        </div>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
