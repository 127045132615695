export const data = [
    {
        id: "1",
        title: "For Boards",
        content:
            "Sustainability Performance Compliance and Governance Decision Intelligence",
        content2: "",
        content3: "",
    },
    {
        id: "2",
        title: "For CXOs",
        content:
            "Drive Sustainable Growth ",
        content2: "Decision Intelligence",
        content3: " Stakeholder Communication",

    },
    {
        id: "3",
        title: "For Investors",
        content:
            "Informed Investment Decisions",
        content2: "Sustainability Due Diligence",
        content3: "Sustainable Portfolio",
    },
    {
        id: "4",
        title: "For ESG Leaders",
        content:
            "Simplified ESG Management ",
        content2: "Drive Org-Wide Adoption",
        content3: "Secure Sustainable Budge",
        content4: "Stakeholder Communication",
    },
    {
        id: "5",
        title: "For ESG Teams",
        content:
            "Collaborate Seamlessly",
        content2: "Streamline Sustainability Projects",
        content3: "Secure Org-Wide Support",
    },
];