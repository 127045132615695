import React from 'react'
import logo from "../images/logo1.png"
function Navbar() {
    return (
        <div className='bg-black flex h-14'>
            <div className='lg:ml-[16px] lg:top-0 lg:mb-[16px] lg:mt-[8px] lg:mr-[8px] md:mt-1 ml-2 mt-[6px] p-[8px]'>
                <img className='sm:h-[32px] h-[28px]' src={logo} alt='logo'></img>
            </div>

            <div className='text-[#f0f0f0] font-Staatliches font-semibold md:text-[26px] sm:text-[22px] lg:text-[28px] text-base lg:ml-0 pt-4 lg:pt-5'>
                <span>TERRAFIC</span>
            </div>
        </div>
    )
}

export default Navbar