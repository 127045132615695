import React, { useState } from 'react';
import hero_img from "../images/Image.png";
import '../App.css';
import ScrollAnimation from 'react-animate-on-scroll';
import { useNavigate } from 'react-router-dom';
import { data } from "../data/data";

function Hero() {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false); // Add loading state
    const navigate = useNavigate();

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateEmail(email)) {
            alert('Please enter a valid email address');
            return;
        }

        setLoading(true); // Start loading spinner

        try {
            const response = await fetch(`https://api.terrafic.earth/api/sub/get/subscriber?email=${email}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/text',
                },
            });

            if (response.ok) {
                setEmail(''); // Clear the input field
                setLoading(false); // Stop loading spinner
                navigate('/confirmation');
            } else {
                setLoading(false); // Stop loading spinner
                alert('Failed to save email');
            }
        } catch (error) {
            setLoading(false); // Stop loading spinner
            alert('An error occurred');
        }
    };

    return (
        <div className='bg-black'>
            <div className=''>
                <img className='h-36 w-full sm:h-auto' src={hero_img} alt=''></img>
                <div className='text-[#ffffff] cursor-default text-center -mt-28 sm:-mt-52 lg:-mt-[328px] 2xl:-mt-[410px] xl:-mt-[376px] text-overlay lg:p-3 md:-mt-[260px] p-4'>
                    <h1 className='text-center hover:scale-95 hover:duration-500 font-Staatliches sm:text-[34px] lg:text-[70px] xl:text-[85px] md:text-[48px] font-[500px] text-[24px] 2xl:text-[75px]'>
                        SUSTAINABILITY NEEDS A REVOLUTION
                    </h1>
                    <h2 className='text-[#ffffff] hover:scale-95 hover:duration-500 font-Staatliches lg:ml-7 lg:pr-12 sm:text-[20px] md:text-[24px] lg:text-[42px] font-[500px] text-[20px]'>
                        AND THE REVOLUTION HAS BEGUN
                    </h2>

                    <form className="w-80 md:w-[350px] lg:w-[600px] font-WorkSans sm:mt-5 mt-14 mx-auto" onSubmit={handleSubmit}>
                        <div className="relative">
                            <input
                                type="email"
                                id="default-email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="block w-full lg:p-4 p-3 ps-4 text-xs text-[#b6b6b6] border border-gray-300 rounded-full focus:ring-white focus:border-white bg-[#030303] dark:border-gray-600 placeholder-gray-400"
                                placeholder="Your Email Address"
                                required
                            />
                            <button
                                type="submit"
                                className="text-black hover:scale-105 hover:duration-300 absolute end-2.5 lg:py-2 bottom-2.5 bg-[#f0f0f0] focus:ring-4 focus:outline-none focus:ring-black font-medium rounded-lg text-xs px-4 py-1 dark:focus:ring-black"
                            >
                                Join The Waitlist
                            </button>
                        </div>
                    </form>


                    {/* Display loading spinner */}
                    {loading && (
                        <div className="flex justify-center p-2 items-center">
                            <svg
                                className="animate-spin h-8 w-8 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                ></circle>
                                <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                ></path>
                            </svg>
                        </div>
                    )}

                    <div className='text-center font-Staatliches md:text-[15px] lg:text-[24px] 2xl:text-[28px] md:p-4 text-sm p-2 font-medium text-[#ffffff]'>
                        LIMITED NUMBER OF WORKSPACES AVAILABLE
                    </div>
                </div>
            </div>

            {/* Additional content below */}
            <div className='text-center font-Staatliches 2xl:text-[55px] lg:text-[52px] p-3 sm:p-6 sm:pt-12 md:pt-16 lg:pt-28 font-[500px] text-[22px] sm:text-[25px] md:text-[34px] text-[#e5e7eb]'>
                <h1 className='md:pt-1 lg:pt-6'>
                    WITH TERRAFIC'S WINGMAN
                </h1>
                <h1 className='md:pt-1 lg:pt-6'>
                    CONTROL YOUR SUSTAINABILITY STRATEGY
                </h1>
                <h1 className='md:pt-1 lg:pt-6'>
                    & EXECUTION, <span className='italic'>IN MINUTES.</span>
                </h1>
            </div>

            <div className="flex gap-8 lg:gap-16 md:pt-12 lg:pt-16 p-6 justify-center content-start flex-wrap">
                {data.map((item) => (
                    <ScrollAnimation key={item.id} delay={80} animateIn="fadeIn">
                        <div className="block hover:shadow-lg hover:shadow-[#024d31] rounded-xl hover:scale-105 duration-300 md:h-[245px] h-auto p-6 w-[340px] m-2 bg-[#024d31]">
                            <h5 className="mb-2 text-left font-Righteous text-[18px] sm:text-xl lg:text-[23px] font-semibold md:leading-tight text-neutral-50">
                                {item.title}
                            </h5>
                            <div className="text-base lg:text-[17px] font-medium md:leading-[45px] text-left font-WorkSans text-neutral-200">
                                {item.content && <p>{item.content}</p>}
                                {item.content2 && <p>{item.content2}</p>}
                                {item.content3 && <p>{item.content3}</p>}
                                {item.content4 && <p>{item.content4}</p>}
                            </div>
                        </div>
                    </ScrollAnimation>
                ))}
            </div>

        </div>
    );
}

export default Hero;
